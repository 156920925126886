*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

blockquote,
body,
dd,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
pre,
ul {
    margin: 0;
}

// Remove default borders
fieldset,
iframe {
    border: 0;
}

// Remove default padding
fieldset,
legend,
ol,
td,
th,
ul {
    padding: 0;
}

svg {
    overflow: visible;
}
svg:not(:root) {
    overflow: visible;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    vertical-align: middle;
}

html {
    min-height: 100%; // [1]
    -webkit-text-size-adjust: 100%; // [2]
    -ms-text-size-adjust: 100%; // [2]
    -moz-osx-font-smoothing: grayscale; // [3]
    -webkit-font-smoothing: antialiased; // [3]
    cursor: default;
    font-style: normal;
}

body {
    height: 100%;
}

button,
a {
    font-weight: 600;
    font-size: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    color: inherit;
    margin: 0;
    padding: 0;
    line-height: normal;
    background-color: transparent;
    font-family: inherit;
}

*::-webkit-scrollbar {
    width: 0;
}
*::-webkit-scrollbar-track {
    width: 0;
}
*::-webkit-scrollbar-thumb {
    width: 0;
}
