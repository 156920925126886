.flex {
    display: flex;
}

.flex__space {
    justify-content: space-between;
}

.flex__align {
    align-items: center;
}

.flex__center {
    align-items: center;
    justify-content: center;
}

.flex__wrap {
    flex-wrap: wrap;
}

.flex__2 {
    flex: 2;
}

.flex__1 {
    flex: 1;
}

.flex__3 {
    flex: 0 0 100%;
}

.mb__hidden {
    display: none;
}

.flex__service {
    flex-direction: column;
    @include mq(sm) {
        flex-direction: row;
        align-items: center;
    }
}