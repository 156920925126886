.grid__3 {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include mq(sm) {
		width: calc((100% / 3) - 1.6rem);
	}

	@include mq(m) {
		width: calc((100% / 3) - 1.6rem);
		margin-right: $base;
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}

.grid__gallery {
	display: flex;
	flex-direction: column;
	width: 100%;
	user-select: none;
	margin-bottom: $base;
	@include mq(sm) {
		width: calc((100% / 2) - 0.8rem);
	}

	@include mq(m) {
		width: calc((100% / 3) - 0.8rem);
		margin-right: $base;
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}
