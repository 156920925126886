// - Fonts
$primary_font: "Open Sans";
$secondary_font: "Roboto Mono";
$font_family: $primary_font, $secondary_font, monospace, Helvetica;

// Base
$base: 0.8rem;

// Media queries
$mq__s: 480px;
$mq__ss: 580px;
$mq__sm: 768px;
$mq__m: 1024px;
$mq__l: 1280px;
$mq__xl: 1440px;
$mq__xxl: 1920px;

// Text
$c_text_primary: #22292f;
$c_text_secondary: #b8c2cc;

// Backgrounds
$c_1: #131c41;
$c_2: #020615;
// $c_1: #2d2b55;
// $c_2: #0a2584;
$c_3: #1b1a36;
$c_4: #2a2e51;
$c_3: #54a0ff;
$c_4: #1dd1a1;

$c_btn: #44e0a6;
$c_btn_p_2: #6696e6;

$c_white: #ffffff;

// shadow
$shadow: 0 2px 2px 2px $c_1;

// Border
$c_border: rgba($c_white, 0.2);

// Animation curve
$ease_scale: cubic-bezier(0.175, 0.885, 0.32, 1.275);
