input,
textarea,
select {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    font-size: inherit;
    font-family: inherit;
    overflow: visible;
    line-height: inherit;
    box-shadow: none;
    &:focus,
    &:active {
        outline: none;
    }
}

textarea {
    resize: vertical;
}

.input {
    width: 100%;
    padding: 0 $base;
    color: $c_1;
    background-color: rgba($c_white, 1);
    border-bottom: 2px solid $c_1;

    height: $base * 4;
    &::placeholder {
        color: rgba($c_1, 0.5);
    }
    &:focus {
        border-color: $c_btn;
    }
}

.select {
    width: 100%;

    color: $c_1;
    background-color: $c_white;
    border: 2px solid $c_1;
    // border-radius: $base * 0.3;
    overflow: hidden;
}

.select__trigger {
    height: $base * 3.6;
    padding: 0 $base;
}

.option {
    height: $base * 4;
    width: 100%;
    padding: $base * 0.7 0;

    padding: 0 $base;
    font-family: $secondary_font;
    font-weight: 500;
    // &:hover {
    //     background-color: rgba($c_1, 1);
    //     color: $c_white;
    // }
}

// .option__active {
//     background-color: rgba($c_1, 1);
// }
