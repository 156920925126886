// .gallery {
//     display: flex;
//     flex-wrap: nowrap;
//     overflow-x: auto;
//     overflow-y: hidden;
//     -webkit-overflow-scrolling: touch;
//     margin-top: $base * 2;
//     &::-webkit-scrollbar {
//         height: $base;
//         background-color: rgba($c_1, 1);
//         border-radius: $base * 0.2;
//     }
//     &::-webkit-scrollbar-track {
//         width: 0;
//     }
//     &::-webkit-scrollbar-thumb {
//         width: $base;
//         height: $base;
//         background-color: $c_4;
//         border-radius: $base * 0.2;
//     }
// }
.slider {
    position: relative;
    overflow: hidden;
}

.slider__container {
    position: relative;
    left: 25px;
    display: flex;
    flex-wrap: nowrap;
}

.item {
    position: relative;
    flex: 0 0 80%;
    background-color: $c_2;
    margin-right: 25px;
    height: 80vh;
    @include mq(s) {
        height: 50vh;
    }
    @include mq(m) {
        height: 65vh;
    }
}

.img__hidden {
    display: none;
}

.img__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.img__background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.img__loading {
    opacity: 0;
    transition: opacity 2200ms ease-out;
}

.img__loaded {
    opacity: 1;
}

.item__service {
    position: relative;
    flex: 0 0 80%;
    background-color: $c_2;
    margin-right: 25px;
    height: 30vh;
    @include mq(sm) {
        height: 50vh;
    }
}

.img__service {
    height: 50vh;
    width: 35rem;
    background-position: center;
    background-size: cover;
}
