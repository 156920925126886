.navigation {
    position: fixed;
    width: 100%;
    padding-top: $base * 2;
    padding-bottom: $base * 2;
    border-bottom: 2px solid rgba($c_2, 0.2);
    z-index: 5;
}

.line {
    position: absolute;
    height: 2px;
    width: $base * 15;
    border-radius: $base * 0.2;
    background-color: $c_btn;
}
