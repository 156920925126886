button,
a {
    -webkit-appearance: none !important;
    writing-mode: none !important;
    border: none;
    box-shadow: none;
    font-family: $secondary_font;
    font-weight: 500;
    font-size: $base * 1.2;
    text-transform: uppercase;
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
    @include mq(s) {
        font-size: $base * 1;
    }
    @include mq(sm) {
        font-size: $base * 1.4;
    }
    @include mq(m) {
        font-size: $base * 1.2;
    }
    @include mq(l) {
        font-size: $base * 2;
    }
}

.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $c_white;
}

.btn__primary {
    padding: $base * 1 $base * 2;
}

.btn__hover {
    transition: color 500ms ease-out;
    &:hover {
        color: $c_btn;
    }
}

.bg__1 {
    background-color: $c_btn;
}

.bg__2 {
    background-color: $c_btn_p_2;
}

.bg__3 {
    background-color: $c_1;
}

.bg__disabled {
    background-color: $c_text_secondary;
}

// .btn__background {
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     background-color: lighten($c_btn_p_1, 0%);
//     z-index: -1;
// }

.line_container {
    position: relative;
    margin-left: $base * 1;
    width: $base * 4;
}
.line {
    width: 100%;
    height: 2px;
    background-color: $c_btn;
}

.dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: $c_btn;
    border-radius: 50%;
}

.dot__hidden {
    position: absolute;
    left: 0;
}
