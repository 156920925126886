.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $c_white;
    color: $c_1;
}

.modal__close {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}

.modal__center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__scroll {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25vh;
    padding-bottom: $base * 6;
}

.modal__slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    max-width: 45 * $base;
    width: 100%;
}

.modal__dot_container {
    position: absolute;
    z-index: 5;
    right: $base * 2;
    top: 50%;
    transform: translateY(-50%);
}

.modal__dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid $c_1;
}

.modal__line {
    width: 2px;
    margin: $base * 0.5 auto;
}
