.intro__container {
    height: 100vh;
    width: 100vw;
    // background-image: radial-gradient(165deg, $c_2 0%, $c_1 74%);
}

.container {
    position: relative;
    padding-top: $base * 7;
    padding-bottom: $base * 7;
}

.container__content {
    @include mq(sm) {
        max-width: $base * 45;
    }
    @include mq(m) {
        max-width: $base * 55;
    }
    @include mq(xl) {
        max-width: $base * 85;
    }
}

.container__webgl {
    position: absolute;
    top: 0;
    left: 0;

    // height: 100vh;
    // width: 100vw;
}

.relative {
    position: relative;
}

.box {
    position: absolute;
}
