.wrap {
    padding-left: $base * 2;
    padding-right: $base * 2;
    @include mq(sm) {
        padding-left: $base * 4;
        padding-right: $base * 4;
    }

    @include mq(l) {
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
