.logo {
	height: $base * 1.2;
	@include mq(s) {
		height: $base * 1.2;
	}
	@include mq(sm) {
		height: $base * 1.4;
	}
	@include mq(m) {
		height: $base * 1.6;
	}
	@include mq(xl) {
		height: $base * 2.4;
	}
}

.icon {
	display: inline-flex;
	stroke-width: 2;
	vertical-align: middle;
	fill: none;
}

.icon__s {
	width: $base * 1.2;
	height: $base * 1.2;
}

.icon__sm {
	width: $base * 1.8;
	height: $base * 1.8;
}
.icon__m {
	width: $base * 2;
	height: $base * 2;
}

.icon__l {
	width: $base * 4;
	height: $base * 4;
}

.spin {
	animation: spin 800ms infinite linear;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
