h1 {
    font-family: $secondary_font;
    line-height: 1;
    font-weight: 500;
    font-size: $base * 2.4;
    @include mq(s) {
        font-size: $base * 3;
    }
    @include mq(sm) {
        font-size: $base * 4.2;
    }
    @include mq(m) {
        font-size: $base * 4.8;
    }
    @include mq(l) {
        font-size: $base * 5.6;
    }
}

p {
    line-height: 1.6;
    font-weight: 400;
    font-size: $base * 1.2;
    @include mq(s) {
        font-size: $base * 1.4;
    }
    @include mq(sm) {
        font-size: $base * 1.4;
    }
    @include mq(m) {
        font-size: $base * 1.6;
    }
    @include mq(xl) {
        font-size: $base * 1.8;
    }
}

.mb__hidden {
    display: none !important;
    @include mq(sm) {
        display: inline-flex !important;
    }
}

.t__u {
    text-transform: uppercase;
    font-size: $base * 1.6;
}

.fw__b {
    font-weight: 500;
}

.fs__xs {
    font-size: $base * 1;
}

.fs__s {
    font-size: $base * 1.2;
}

.fs__xl {
    font-size: $base * 1.8;
}

.center {
    text-align: center;
}
