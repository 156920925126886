.pt__s {
    padding-top: $base * 1;
}

.pt__m {
    padding-top: $base * 2;
}

.pt__l {
    padding-top: $base * 4;
}

.pl__xs {
    padding-left: $base * 0.5;
}

.pl__s {
    padding-left: $base * 1;
}

.pl__l {
    padding-left: $base * 4;
}

.pr__xs {
    padding-right: $base * 0.5;
}

.pr__s {
    padding-right: $base * 1;
}

.pr__l {
    padding-right: $base * 4;
}

.pb__xs {
    padding-bottom: $base * 0.5;
}

.pb__m {
    padding-bottom: $base * 2;
}

.p__box {
    padding: $base * 2;
}
