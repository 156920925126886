.loader__container {
    position: relative;
    display: inline-block;
    margin-top: $base * 3;
    width: 15 * $base;
    height: 15 * $base;
    // background: $c_3;
}

.loader {
    top: 0;
    left: 0;

    position: absolute;
    width: 5 * $base;
    height: 5 * $base;
    background-color: $c_3;
}
