@mixin mq($break) {
    @if $break == "s" {
        @media (min-width: 0px) and (max-width: $mq__s) {
            @content;
        }
    } @else if $break == "sb" {
        @media (min-width: $mq__s + 1) {
            @content;
        }
    } @else if $break == "ss" {
        @media (min-width: $mq__ss + 1) {
            @content;
        }
    } @else if $break == "sm" {
        @media (min-width: $mq__sm) {
            @content;
        }
    } @else if $break == "m" {
        @media (min-width: $mq__m + 1) {
            @content;
        }
    } @else if $break == "l" {
        @media (min-width: $mq__l + 1) {
            @content;
        }
    } @else if $break == "xl" {
        @media (min-width: $mq__xl + 1) {
            @content;
        }
    } @else if $break == "xxl" {
        @media (min-width: $mq__xxl + 1) {
            @content;
        }
    } @else {
        @error "No value could be retrieved for `#{$break}`. ";
    }
}
