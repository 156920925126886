.c__secondary {
    color: $c_1;
}

.c__white {
    color: $c_white;
}

.c__main {
    color: $c_btn;
}
